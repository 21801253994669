import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { loyaltyJoinNowOrViewPoints, loyaltyProgramOpen } from '@wix/bi-logger-loyalty-data/v2';
import { loadLoyaltyCouponNames } from '@wix/loyalty-coupon-names';
import { createEventHandler } from '@wix/tpa-settings';
import { CreateControllerFn, IUser } from '@wix/yoshi-flow-editor';

import { SocialMediaChannel } from '../../types/domain';
import { SettingsEvents, TabState } from '../../types/settings';
import {
  createCurrencyFormatter,
  getDecodedInstance,
  getDemoContent,
  getLocale,
  getMyRewardsPageUrl,
  isProgramAvailable,
  loadData,
  loadUserData,
  navigateToMyRewardsPage,
  promptLogin,
} from '../../utils';
import { getMemberAccount, updateMemberBirthday } from '../../utils/member';
import { followChannel } from '../../utils/social-media';
import { toSimpleRewards } from '../../utils/to-simple-rewards';
import { toSimpleRules } from '../../utils/to-simple-rules';
import { toSimpleTiers } from '../../utils/to-simple-tiers';
import { BirthdayState } from './Widget/constants';
import { ControllerProps } from './Widget/types';

const createController: CreateControllerFn = async ({ controllerConfig, flowAPI }) => {
  const { config, setProps, wixCodeApi } = controllerConfig;
  const componentEventHandler = createEventHandler<SettingsEvents>(config.publicData.COMPONENT || {});
  const { httpClient } = flowAPI;
  const { isViewer, isEditor } = flowAPI.environment;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;
  const formatCurrency = createCurrencyFormatter(flowAPI);

  const setWidgetProps = (props: Partial<ControllerProps>) => {
    setProps(props);
  };

  // See: https://wix.slack.com/archives/C01480U2SAF/p1643623573887269
  setProps({ fitToContentHeight: true });

  return {
    async pageReady() {
      componentEventHandler.on('tabState', (tabState: TabState) => setWidgetProps({ tabState }));
      componentEventHandler.onReset(() => setWidgetProps({ tabState: TabState.BeforeSignUp }));

      try {
        const isSiteTemplate = getDecodedInstance(flowAPI).siteIsTemplate ?? false;
        const shouldPreloadLoyaltyProgram = !isViewer || isSiteTemplate;

        const [loyaltyProgramResponse, couponNames] = await Promise.all([
          shouldPreloadLoyaltyProgram
            ? withErrorHandler(() => httpClient.request(getLoyaltyProgram({})), { errorCodesMap: {} })
            : undefined,
          loadLoyaltyCouponNames({ i18n: flowAPI.translations.i18n, formatCurrency }),
        ]);

        const preloadedLoyaltyProgram = loyaltyProgramResponse?.data.loyaltyProgram;
        const hasCompletedSetup = !!preloadedLoyaltyProgram?.name;
        const useDemoContent = (!isViewer && !hasCompletedSetup) || isSiteTemplate;
        let isLoggedIn = wixCodeApi.user.currentUser.loggedIn;
        let birthday: string | undefined;

        const { earningRules, rewards, tiers, tiersProgramSettings, loyaltyProgram, currentTierId, followedChannels } =
          useDemoContent
            ? await getDemoContent(flowAPI, preloadedLoyaltyProgram!)
            : await loadData(flowAPI, preloadedLoyaltyProgram);

        const myRewardsPageUrl = await getMyRewardsPageUrl(flowAPI);
        const hasMyRewardsPage = isEditor ? true : !!myRewardsPageUrl;

        const handleFollowChannel = async (type: SocialMediaChannel) => {
          if (!isViewer) {
            return;
          }

          await followChannel(type, flowAPI);
          followedChannels.push(type);
          setWidgetProps({
            followedChannels,
          });
        };

        const handleNavigateToMyRewardsPage = async () => {
          if (isViewer) {
            flowAPI.bi?.report(
              loyaltyJoinNowOrViewPoints({
                button: isLoggedIn ? 'view_points' : 'join_program',
                role: wixCodeApi.user.currentUser.role,
              }),
            );
          }

          const shouldNavigateToMyRewardsPage = isLoggedIn ? true : await promptLogin(flowAPI);

          if (shouldNavigateToMyRewardsPage) {
            await navigateToMyRewardsPage(flowAPI);
          }
        };

        const handleUpdateBirthday = async (newBirthday: string) => {
          setWidgetProps({ birthdayState: BirthdayState.UpdatingBirthday });
          try {
            await updateMemberBirthday(newBirthday, flowAPI);
            setWidgetProps({ birthdayState: BirthdayState.SuccessToastVisible, birthday: newBirthday });
            birthday = newBirthday;
          } catch (error) {
            setWidgetProps({ birthdayState: BirthdayState.ErrorToastVisible });

            if (error instanceof Error) {
              flowAPI.reportError(error);
            }
          }
        };

        const handleOpenBirthdayModal = async () => {
          if (!isLoggedIn) {
            const hasLoggedIn = await promptLogin(flowAPI);
            if (!hasLoggedIn) {
              return;
            }
          }
          if (!birthday) {
            setWidgetProps({ birthdayState: BirthdayState.ModalLoading });

            const memberAccountResponse = await getMemberAccount(flowAPI);
            birthday = memberAccountResponse?.member?.contact?.birthdate ?? undefined;
          }

          setWidgetProps({ birthdayState: BirthdayState.ModalLoaded, birthday });
        };

        const handleCloseBirthdayModal = () => {
          setWidgetProps({ birthdayState: undefined });
        };

        setWidgetProps({
          simpleRules: toSimpleRules({ rules: earningRules, flowAPI, loyaltyProgram }),
          simpleRewards: toSimpleRewards({
            rewards,
            flowAPI,
            loyaltyProgram,
            couponNames,
            tiers,
          }),
          simpleTiers: toSimpleTiers(tiers),
          loyaltyProgram,
          isLoggedIn: isEditor ? false : isLoggedIn,
          hasMyRewardsPage,
          myRewardsPageUrl,
          onNavigateToMyRewardsPage: handleNavigateToMyRewardsPage,
          onFollowSocialMediaChannel: handleFollowChannel,
          onUpdateBirthday: handleUpdateBirthday,
          onOpenBirthdayModal: handleOpenBirthdayModal,
          onCloseBirthdayModal: handleCloseBirthdayModal,
          tiersProgramSettings,
          isProgramAvailable: isProgramAvailable({ loyaltyProgram, isSiteTemplate, isViewer }),
          locale: getLocale(flowAPI),
          currentTierId,
          followedChannels,
          birthday,
        });

        wixCodeApi.user.onLogin(async (user: IUser) => {
          isLoggedIn = user.loggedIn;
          const userData = await loadUserData(flowAPI, earningRules, tiersProgramSettings);
          setWidgetProps({ isLoggedIn, ...userData });
        });

        // NOTE: "action=signup" param is used in emails
        if (isViewer && !isLoggedIn) {
          const queryParams = wixCodeApi.location.query;
          if (queryParams.action === 'signup') {
            promptLogin(flowAPI, 'signup');
          }
        }

        if (isViewer) {
          flowAPI.bi?.report(
            loyaltyProgramOpen({
              response: loyaltyProgram.status,
              role: wixCodeApi.user.currentUser.role,
            }),
          );
        }
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          flowAPI.reportError(error);
        }
        setWidgetProps({
          errorMessage: getResolvedError(error).message,
        });
        flowAPI.bi?.report(
          loyaltyProgramOpen({
            role: wixCodeApi.user.currentUser.role,
          }),
        );
      }
    },
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
  };
};

export default createController;
